/**
 * humburger
 */
export function humburger_menu() {
  const hamButton = document.getElementById('hamburger-button');
  const humBody   = document.getElementById('body');
  const hamDrawer = document.getElementById('drawer');

  hamButton.addEventListener('click', function() {
    hamButton.classList.toggle('is-open');
    humBody.classList.toggle('is-locked');

    if ( hamDrawer.getAttribute('aria-expanded') == 'false' ) {
      hamDrawer.setAttribute('aria-expanded', true);
    } else {
      hamDrawer.setAttribute('aria-expanded', false);
    }
    if ( hamDrawer.getAttribute('aria-expanded') == 'true' ) {
      hamDrawer.setAttribute('aria-hidden', false);
    } else {
      hamDrawer.setAttribute('aria-hidden', true);
    }

    // let headerHeight = document.getElementById('site-header').clientHeight + 5;
    // document.getElementById('drawer-header').style.height = headerHeight + 'px';
  }, false);

  // window.addEventListener('resize', function() {
  //   let headerHeightResize = document.getElementById('site-header').clientHeight + 5;
  //   document.getElementById('drawer-header').style.height = headerHeightResize + 'px';
  // });

  /**
   * ドロワーメニュー内のアンカーリンク#を押した時に閉じる
   * drawer > .drawer-menu > li > a:href #
   */
  const humDrawerLink = document.querySelector('.drawer-menu a[href^="/#"]');
  if (!humDrawerLink){
    return false;
  }

  humDrawerLink.addEventListener('click', function() {
    hamButton.classList.remove('is-open');
    humBody.classList.remove('is-locked');

    if ( hamDrawer.getAttribute('aria-expanded') == 'false' ) {
      hamDrawer.setAttribute('aria-expanded', true);
    } else {
      hamDrawer.setAttribute('aria-expanded', false);
    }
    if ( hamDrawer.getAttribute('aria-expanded') == 'true' ) {
      hamDrawer.setAttribute('aria-hidden', false);
    } else {
      hamDrawer.setAttribute('aria-hidden', true);
    }

  }, false);
}

/**
 * ドロワーメニューの開閉
 */
export function drawer_menu() {
  document.addEventListener('DOMContentLoaded', function () {
    const toggleButtons = document.querySelectorAll('.menu-item-has-children .submenu-toggle');

    toggleButtons.forEach(function (btn) {
      btn.addEventListener('click', function (event) {
        event.stopPropagation();
        const parentMenuItem = btn.closest('.menu-item-has-children');
        const submenu = parentMenuItem.querySelector('.sub-menu');

        if (submenu) {
          const isOpen = parentMenuItem.classList.contains('is-open');
          if (!isOpen) {
            // サブメニューを開く
            submenu.style.display = 'block';
            // displayがblockになるまでの遅延を確実にする
            setTimeout(() => {
              submenu.style.maxHeight = submenu.scrollHeight + "px";
            }, 10);
            parentMenuItem.classList.add('is-open');
          } else {
            // サブメニューを閉じる
            submenu.style.maxHeight = '0';
            submenu.addEventListener('transitionend', () => {
              // トランジションが完了してからdisplayをnoneに設定
              if (!parentMenuItem.classList.contains('is-open')) {
                submenu.style.display = 'none';
              }
            }, { once: true });
            parentMenuItem.classList.remove('is-open');
          }
        } else {
          console.log('サブメニューがこの要素には存在しません。');
        }
      });
    });
  });
}
