/**
 * Copy to Clipboard
 */

// copy to clipboard
export function copy_to_clipboard() {
  document.addEventListener('DOMContentLoaded', (event) => {
    // 全てのコピーボタンにイベントリスナーを設定
    document.querySelectorAll('.copy-btn').forEach(button => {
      button.addEventListener('click', function() {
        // このボタンのdata-clipboard-text属性からテキストを取得
        const text = this.getAttribute('data-clipboard-text');
        // テキストをクリップボードにコピー
        navigator.clipboard.writeText(text).then(() => {
            // コピー成功時の処理
            alert('クリップボードにコピーしました!');
        }).catch(err => {
            // コピー失敗時の処理
            console.error('コピーに失敗しました: ', err);
        });
      });
    });
  });
}
