/**
 * JavaScript for Theme setting.
 */

// window size
export function window_size() {
  let windowWidth;
  let windowheight;
  let scrollbarWidth;
  let headerheight;
  let navheight;
  let footerctaheight;
  let footerheight;
  let breadcrumbsheight;
  let updownheight;
  let topmv;
  let decol;

  // ページ読み込み時
  window.addEventListener('DOMContentLoaded', function(){
    // ブラウザ内の表示域(スクロールバーを除く)
    windowWidth = document.documentElement.clientWidth;
    windowheight = document.documentElement.clientHeight;
    scrollbarWidth = window.innerWidth - windowWidth;
    headerheight = document.getElementById('site-header').clientHeight;
    navheight = document.getElementById('header-global-nav').clientHeight;
    footerctaheight = document.getElementById('footer-cta').clientHeight;
    footerheight = document.getElementById('site-footer').clientHeight;
    if ( document.getElementById('top-mv') !== null ) {
      topmv = document.getElementById('top-mv').clientHeight;
    }
    if ( document.getElementById('deco-l') !== null ) {
      decol = document.getElementById('deco-l').clientHeight;
    }
    if ( document.getElementById('breadcrumbs') === null ) {
      breadcrumbsheight = 0;
    } else {
      breadcrumbsheight = document.getElementById('breadcrumbs').clientHeight;
    }
    updownheight = headerheight + navheight + breadcrumbsheight + footerheight;

    document.documentElement.style.setProperty('--window-width', windowWidth);
    document.documentElement.style.setProperty('--window-height', windowheight);
    document.documentElement.style.setProperty('--window-width-px', (windowWidth) + 'px');
    document.documentElement.style.setProperty('--window-height-px', (windowheight) + 'px');
    document.documentElement.style.setProperty('--header-height-px', (headerheight) + 'px');
    document.documentElement.style.setProperty('--nav-height', navheight);
    document.documentElement.style.setProperty('--nav-height-px', (navheight) + 'px');
    document.documentElement.style.setProperty('--footer-cta-height-px', (footerctaheight) + 'px');
    document.documentElement.style.setProperty('--footer-height-px', (footerheight) + 'px');
    document.documentElement.style.setProperty('--updown-height-px', (updownheight) + 'px');
    document.documentElement.style.setProperty('--scrollbar-width-px', (scrollbarWidth) + 'px');
    document.documentElement.style.setProperty('--top-mv', (topmv) + 'px');
    document.documentElement.style.setProperty('--deco-l', (decol) + 'px');
  });

  // ウィンドウのサイズ変更イベントの設定
  window.addEventListener('resize', function(){
    windowWidth = document.documentElement.clientWidth;
    windowheight = document.documentElement.clientHeight;
    scrollbarWidth = window.innerWidth - windowWidth;
    headerheight = document.getElementById('site-header').clientHeight;
    navheight = document.getElementById('header-global-nav').clientHeight;
    footerctaheight = document.getElementById('footer-cta').clientHeight;
    footerheight = document.getElementById('site-footer').clientHeight;
    if ( document.getElementById('top-mv') !== null ) {
      topmv = document.getElementById('top-mv').clientHeight;
    }
    if ( document.getElementById('deco-l') !== null ) {
      decol = document.getElementById('deco-l').clientHeight;
    }
    if ( document.getElementById('breadcrumbs') === null ) {
      breadcrumbsheight = 0;
    } else {
      breadcrumbsheight = document.getElementById('breadcrumbs').clientHeight;
    }
    updownheight = headerheight + breadcrumbsheight + footerheight;
    document.documentElement.style.setProperty('--window-width', windowWidth);
    document.documentElement.style.setProperty('--window-height', windowheight);
    document.documentElement.style.setProperty('--window-width-px', (windowWidth) + 'px');
    document.documentElement.style.setProperty('--window-height-px', (windowheight) + 'px');
    document.documentElement.style.setProperty('--header-height-px', (headerheight) + 'px');
    document.documentElement.style.setProperty('--nav-height', navheight);
    document.documentElement.style.setProperty('--nav-height-px', (navheight) + 'px');
    document.documentElement.style.setProperty('--footer-cta-height-px', (footerctaheight) + 'px');
    document.documentElement.style.setProperty('--footer-height-px', (footerheight) + 'px');
    document.documentElement.style.setProperty('--updown-height-px', (updownheight) + 'px');
    document.documentElement.style.setProperty('--scrollbar-width-px', (scrollbarWidth) + 'px');
    document.documentElement.style.setProperty('--top-mv', (topmv) + 'px');
    document.documentElement.style.setProperty('--deco-l', (decol) + 'px');
  });
}
