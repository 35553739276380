import "@babel/polyfill";
import { window_size } from "./modules/theme";
import { viewport_less_than_360 } from "./modules/viewport";
import { humburger_menu, drawer_menu } from "./modules/humburger-menu";
import { copy_to_clipboard } from "./modules/clipboard";
import { scroll_hint } from "./modules/scrollhint";

window_size();
viewport_less_than_360();
humburger_menu();
drawer_menu();
copy_to_clipboard();
scroll_hint();
